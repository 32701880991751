*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&family=Roboto:wght@100;300;400;700&display=swap");
html {
  font-size: 100%;
  scroll-behavior: smooth;
} /*16px*/

:root {
  /* colors */
  /* bg like navy blue */
  --bg-color: #0b6916;
  --primary-color: #fed700;
  --hr-color: #c4c4c4;
  --primary-50: #e0fcff;
  --primary-100: #bef8fd;
  --primary-200: #87eaf2;
  --primary-300: #54d1db;
  --primary-400: #38bec9;
  --primary-500: #2cb1bc;
  --primary-600: #14919b;
  --primary-700: #0e7c86;
  --primary-800: #0a6c74;
  --primary-900: #044e54;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: "Roboto", Sans-Serif;
  --bodyFont: "PT Sans", sans-serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 6rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}


.ning{
  color: var(--white);
}
body {
  background: var(--white);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--grey-900);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: bold;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.map-cont{
  height: 300 !important;
  width: 100%;
}
#google-maps{
  height: 300 !important;
  width: 100%;
}



.required:after {
  content:" *";
  color: red;
}
/* form{
  text-transform: uppercase;
} */
.pars{
  height: calc(100vh - 104px);
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}
h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}


small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}
a,
button {
  line-height: 1.15;
}
button:disabled {
  cursor: not-allowed;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.my-btn {
  cursor: pointer;
  color: var(--white);
  background: var(--bg-color);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
  font-weight: bold;
}
.back-btn {
  cursor: pointer;
  color: green;
  background: transparent;
  border: green;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
  font-weight: bold;
}

.progress-active{
  font-weight: bold;
  text-transform: lowercase;
}
.progress-inactive{
  font-weight: normal;
  text-transform: lowercase;
  color:#829ab1
}
.redz{
  background: #FF0000;
}
.yellow-clr{
  background-color: #0b6916;
}
.my-btn:hover {
  /* background:#269e34; */
  /* color: #269e34; */
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}
.btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}

.btn-clear {
  background: rgb(50, 50, 221);
}

.btn-clear:hover {
  background: var(--grey-500);
}
.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}
.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}
/* alerts */
.my-alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
}

.my-alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.my-alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
  transition: var(--transition);
  text-transform: uppercase;
}
.form:hover {
  box-shadow: var(--shadow-4);
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form_input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--white);
  border: 1px solid var(--grey-200);
  text-transform: uppercase;
}
.form-multi{
  background: var(--backgroundColor) !important;
}


.css-1r4vtzz {
  width: 100% !important;  
}  
.form_input,
.form-textarea,
.form_check:checked,
.form-select:focus {
  border: 1px solid var(--grey-200) !important;
}
.form-check-input:checked{
  border-color: var(--grey-200) !important;
  background-color: #0b6916 !important;
  color: var(--white);
}
.form_input,
.form-select,
.btn-block {
  height: 45px;
}
.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

.webcam-btn{
  background-color: black;
}

.btn-row{
  width: 220px;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }  
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}
.loading-center {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

/* .container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
} */
.full-page {
  min-height: 100vh;
}

.coffee-info {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: var(--letterSpacing);
}
.coffee-info span {
  display: block;
}
.coffee-info a {
  color: var(--primary-500);
}

@media screen and (min-width: 992px) {
  .coffee-info {
    text-align: left;
  }
  .coffee-info span {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 800px) {
  .hides{
    display: none !important;
  }
}
